import React, { Suspense } from "react";
import { Canvas } from "react-three-fiber";
import Horse from "./Pink-Horse.js";

import { OrbitControls, Stage } from '@react-three/drei'
import "./styles.css";

export default function App() {
  return (
    <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 0, 0], fov: 100 }}>
      <ambientLight intensity={0.6} />
      <directionalLight intensity={0.5} />
      <Suspense fallback={null}>
        <Stage environment="sunset" intensity={0.1} contactShadowOpacity={0.6} contactShadowBlur={1}>
          <Horse scale={1} position={[0, 0, 0]} />
        </Stage>
      </Suspense>
      <OrbitControls autoRotate />
    </Canvas>
  );
}
