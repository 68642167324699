/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF(
    "https://firebasestorage.googleapis.com/v0/b/epic-horse.appspot.com/o/pink-horse.gltf?alt=media&token=2c138ed3-8ccc-40bd-b642-98a0e20f2057"
  );
  return (
    <group ref={group} {...props} dispose={null}>
      <primitive object={nodes.Base} />
      <primitive object={nodes.IK_BackR} />
      <primitive object={nodes.IK_BackL} />
      <primitive object={nodes.IK_FrontR} />
      <primitive object={nodes.IK_FrontL} />
      <skinnedMesh
        geometry={nodes.Cylinder002.geometry}
        material={materials.Brown}
        skeleton={nodes.Cylinder002.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Cylinder002_1.geometry}
        material={materials.UnderBelly}
        skeleton={nodes.Cylinder002_1.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Cylinder002_2.geometry}
        material={materials['Black.001']}
        skeleton={nodes.Cylinder002_2.skeleton}
      />
      <skinnedMesh
        geometry={nodes.Cylinder002_3.geometry}
        material={materials.HorseShoe}
        skeleton={nodes.Cylinder002_3.skeleton}
      />
    </group>
  )
}

useGLTF.preload(
  "https://firebasestorage.googleapis.com/v0/b/epic-horse.appspot.com/o/pink-horse.gltf?alt=media&token=2c138ed3-8ccc-40bd-b642-98a0e20f2057"
);
